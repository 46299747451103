import { useViewportSize } from 'hooks'
import { PropsWithChildren, createContext, useContext } from 'react'

type ViewportContextOutput = {
  width: number
  height: number
}

export const getViewportContext = () => {
  const ViewportContext = createContext<ViewportContextOutput | undefined>(undefined)

  const ViewportProvider = ({ children }: PropsWithChildren): JSX.Element => {
    const { width, height } = useViewportSize()
    return (
      <ViewportContext.Provider
        value={{
          width,
          height,
        }}
      >
        {children}
      </ViewportContext.Provider>
    )
  }

  const useViewport = (): ViewportContextOutput => {
    const context = useContext(ViewportContext)
    if (context === undefined) {
      throw new Error('useViewport must be used within a ViewportProvider')
    }
    return context
  }
  return { ViewportProvider, useViewport }
}
