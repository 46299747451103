var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"09447a94aee8dc986e15d45f4de8c8b4cf61f4fb"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { Replay, init as initSentry } from '@sentry/nextjs'
import { filterEventsByEnv, ignoredClientErrorMessages } from 'sentry-utils'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT =
  process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT
const isProd =
  !['development', 'test'].includes(process.env.NODE_ENV) && SENTRY_ENVIRONMENT === 'production'

if (isProd) {
  initSentry({
    dsn:
      SENTRY_DSN ||
      'https://7dc0eafe0837d9f3e2f9817e3a2efcf8@o1299879.ingest.sentry.io/4508103371522048',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    environment: SENTRY_ENVIRONMENT,
    tracesSampleRate: SENTRY_ENVIRONMENT === 'production' ? 1.0 : 0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    beforeSendTransaction: filterEventsByEnv('production'),
    beforeSend: filterEventsByEnv('production'),
    ignoreErrors: ignoredClientErrorMessages,
  })
}
